.m2kfb{
    width: 90%;
    height: 50px;
    margin: auto;
    background-color: #52D9D9;
    border-radius: 0 0 50px 50px;
    opacity: 0.5;
    filter: blur(50px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}