@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
  font-family: 'Questrial', sans-serif;
  /* font-family: fedra, sans-serif; */
}
.bg-primary{
  background-color: #52D9D9;
}
.text-primary{
  color: #52D9D9;
}
.border-primary{
  border-color: #52D9D9;
}
.bg-secondary{
  background-color: #17152F;
}
.text-secondary{
  color: #17152F
}
.bg-primary-gradient{
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.6%, #000000 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 42%), linear-gradient(90.33deg, #17152F 32.92%, #17152F 99.73%)
}
