/* Chatbot.css */


.chatbot-container {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #5C4E83;
  /* Fallback color */

  /* Gradient background */
}


.chatbot-container.expanded {

  height: 100vh; 
  transition: width 0.3s ease, height 0.3s ease; 
}


.navbar {
  background-color: #4C406D;
  color: white;
  padding: 10px;
  font-size: 30px;
  text-align: center;
  font-weight: normal;
  border-radius: 30px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.message-container {
  display: flex;
  justify-content: flex-start;
  margin: 5px;
}


.message {
  background-color: #4C406D;
  padding: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-radius: 8px;
  border-top-left-radius: 0px;
  max-width: 100%;
  /* word-wrap: break-word; */
}

.user {
  background-color: white;
  color: black;
  max-width: 100%;
  border-radius: 8px;
  border-top-right-radius: 0px;
  align-self: flex-end;
}

.assistant {
  /* background-color: rgb(75, 185, 193); */
  color: white;
  align-self: flex-start;
  max-width: 100%;
  /* Adjust the width as needed */
  left: 30%;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 25px;
  margin: 6px;
  background-color: #fff;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 8px;
  outline: none;
}

.chat-input button {
  background-color: #0374ed;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  margin-left: 10px;
  cursor: pointer;
}

/* Add this to Chatbot.css */
.profile-picture {
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  border-radius: 50%;
  margin-right: 10px;
}

.code-line {
  font-family: 'Courier New', monospace;
  background-color: #f4f4f4;
  padding: 0.5em;
  border-radius: 3px;
  margin: 0.2em 0;
}


#message-container::-webkit-scrollbar {
  width: 12px;
}

#message-container::-webkit-scrollbar-thumb {
  background-color: #95A5A6;
  border-radius: 6px;
}

#message-container::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 6px;
}


@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 2s ease-in-out infinite;
}

/* loader animation */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}